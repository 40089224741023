@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply w-full h-full scroll-smooth;
}

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/Open_Sans/static/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Didact";
  src: url("./assets/fonts/Didact_Gothic/DidactGothic-Regular.ttf");
}
@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/Noto_Sans_Bengali/static/NotoSansBengali-Regular.ttf");
}
@font-face {
  font-family: "NotoSerif";
  src: url("./assets/fonts/Noto_Serif_Bengali/static/NotoSerifBengali-Regular.ttf");
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
    margin: 0;
  }
}
